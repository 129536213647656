<template>
  <dialog
    ref="dialogElement"
    :class="{
      side: type === 'side',
      default: type === 'default',
    }"
  >
    <div>
      <slot />
    </div>

    <slot name="close">
      <button
        class="absolute top-10 right-0 left-0 md:left-auto mx-auto md:mx-0 md:right-10 w-13 h-13 rounded-[18px] [ transform ] bg-secondary-300 md:bg-transparent [ flex items-center justify-center ] z-1 -translate-y-1/2 md:transform-none"
        @click="closeDialog"
      >
        <svg-icon name="close" class="h-6 w-6" />
      </button>
    </slot>
  </dialog>
</template>

<script setup lang="ts">
const closeModalOnBackdropClick = (element: Ref) => (callback: () => void) => (event: MouseEvent) => {
  const rect = element?.value.getBoundingClientRect();
  const isInDialog =
    event.clientX >= rect.left &&
    event.clientX <= rect.right &&
    event.clientY >= rect.top &&
    event.clientY <= rect.bottom;

  if (!isInDialog) {
    callback();
  }
};

const dialogElement = ref<HTMLDialogElement | null>(null);
const props = withDefaults(
  defineProps<{
    type?: 'side' | 'default';
    modelValue: boolean;
  }>(),
  {
    type: 'default',
    modelValue: false,
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

const hideModal = () => {
  dialogElement?.value?.close();
};

const displayModal = () => {
  dialogElement?.value?.showModal();
};

const closeDialog = () => {
  emit('update:modelValue', false);
};

onMounted(() => {
  dialogElement?.value?.addEventListener(
    'click',
    closeModalOnBackdropClick(dialogElement)(() => closeDialog()),
  );

  dialogElement?.value?.addEventListener('close', () => {
    emit('update:modelValue', false);
  });
});

onBeforeUnmount(() => {
  dialogElement?.value?.removeEventListener(
    'click',
    closeModalOnBackdropClick(dialogElement)(() => closeDialog()),
  );

  dialogElement?.value?.removeEventListener('close', () => {
    emit('update:modelValue', false);
  });
});

watch(
  () => props.modelValue,
  value => {
    if (value) {
      displayModal();
    } else {
      hideModal();
    }
  },
);

const route = useRoute();

watch(
  () => route.path,
  () => {
    closeDialog();
  },
  {
    deep: true,
  },
);
</script>

<style lang="postcss" scoped>
.side {
  @apply BubbleDialog;
  & > div {
    @apply BubbleContainer h-full pb-10;
  }
  @screen md {
    clip-path: none;
    @apply SideDialog;

    & > div {
      clip-path: none;
      height: 100%;
    }
  }
}

.default {
  animation: slide-down 0.4s ease-out;
  border-radius: 32px;
  padding: 0;

  &[open] {
    animation: slide-up 0.4s ease-out;
  }

  &::backdrop {
    animation: fade-in 0.4s ease-out forwards;
  }
}

.SideDialog {
  @apply pl-20 pr-6 py-6 rounded-l-8xs bg-white;

  @screen md {
    top: 0;
    bottom: 0;
    height: 100%;
    width: 60vw;
    left: auto;
    margin: unset;
    position: fixed;
  }

  animation: slide-down 0.4s ease-out;
  @screen md {
    animation: slide-out 0.4s ease-out;
  }
  border: unset;
  max-height: none;

  &[open] {
    animation: slide-up 0.4s ease-out;
    @screen md {
      animation: slide-in 0.4s ease-out;
    }
  }

  &::backdrop {
    animation: fade-in 0.4s ease-out forwards;
  }
}

.BubbleContainer {
  @apply px-5 md:px-0 max-h-[690px] md:max-h-none pt-10 md:pt-0;

  background: rgb(255, 255, 255);
  clip-path: ellipse(125% 94% at 50% 100%);
  overflow: auto;
}

.BubbleDialog {
  position: fixed;
  background: transparent;
  top: auto;
  bottom: 0;
  margin: unset;
  border: unset;
  max-width: none;
  overflow: hidden;
  width: 100%;
  height: 700px;

  animation: slide-down 0.4s ease-out;
  &[open] {
    animation: slide-up 0.4s ease-out;
  }

  &::backdrop {
    animation: fade-in 0.4s ease-out forwards;
  }
}

/* Animation keyframes */
@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(100%);
    display: none;
  }

  100% {
    opacity: 1;
    transform: translateX(0);
    display: block;
  }
}

@keyframes slide-out {
  0% {
    opacity: 1;
    transform: translateX(0);
    display: block;
  }

  100% {
    opacity: 0;
    transform: translateX(100%);
    display: none;
  }
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(100%);
    display: none;
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-down {
  0% {
    opacity: 1;
    transform: translateY(0);
    display: block;
  }

  100% {
    opacity: 0;
    transform: translateY(100%);
    display: none;
  }
}

@keyframes fade-in {
  0% {
    background-color: rgb(0 0 0 / 0%);
  }

  100% {
    background-color: rgb(0 0 0 / 25%);
  }
}
</style>
